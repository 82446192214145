@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  overflow-x: hidden;
}

body {
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Work Sans", sans-serif !important;
}

* {
  outline: none;
}

a {
  text-decoration: none;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s
}

p {
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.container {
  max-width: 1350px;
  padding: 0px 30px;
}

.menu_sidebar .logo_box {
  text-align: center;
  margin-bottom: 50px;
}

.menu_sidebar .logo_box img {
  width: 50px;
  height: auto;
}

.menu_sidebar {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999999;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: 90px;
  min-width: 90px;
  padding: 15px;
  -webkit-transition: all 0.01 ease-in-out;
  transition: all 0.01 ease-in-out;
  background: #181230;
  box-shadow: rgb(85, 85, 85) -3px 0px 5px 0px;
  transition: .5s ease all;
}

.menu_sidebar .menu_box ul {
  padding-left: 0px;
  list-style: none;
}

.menu_sidebar .menu_box ul li {
  margin-bottom: 5px;
}

.menu_sidebar .menu_box ul li a {
  color: #1b2861;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  padding: 15px 20px;
  display: block;
  position: relative;
  word-break: break-all;
  white-space: inherit;
  border-radius: 5px;  
}

.menu_sidebar .menu_box ul li a:hover {
  background-color: #5e38ea4a;
}

.menu_sidebar .menu_box ul li a svg {
  color: #fff;
  font-size: 20px;
  vertical-align: middle;
  margin-right: 6px;
}

.menu_sidebar .menu_box ul li a.active {
  color: #fff;
  background-color: #5e38ea;
}

.dashboard_body {
  padding-left: 90px;
  min-height: 600px;
}

.dashboard_body .tophead_mainsec > .row {
  align-items: center;
}

.dashboard_body .tophead_mainsec {
  padding: 10px 25px 10px;
  border-bottom: 1px solid #e4e4e4;
}

.title_mainsec h2 {
  color: #5e38ea;
  font-size: 26px;
  font-weight: 600;
  line-height: 37px;
  margin-bottom: 0px;
}

.dashboard_body .tophead_mainsec .btn_mainsec {
  text-align: right;
}

.btn_mainsec .btn_style {
  background-color: #5e38ea;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  padding: 10px 20px;
  display: inline-block;
  text-transform: capitalize;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.btn_mainsec .btn_style:hover {
  color: #fff;
  border-radius: 6px;
}

.btn_mainsec .btn_style::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #181230;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  border-radius: 6px;
}

.btn_mainsec .btn_style:hover::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.email_listing_mainsec {
  padding: 25px;
}

.table_mainsec .table thead tr th {
  color: #5e38ea;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 15px 15px 0px;
  text-align: center;
}

.table_mainsec .table thead tr th:first-child {
  text-align: left;
}

.table_mainsec .table tbody tr td {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  padding: 15px 15px 15px 0px;
  text-align: center;
}

.table_mainsec .table tbody tr td:first-child {
  text-align: left;
}

.table_mainsec .table tbody tr td a {
  color: #000;
}

.table_mainsec .table tbody tr td a:hover {
  color: #5e38ea;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-toggle::after {
  border: none;
}

.profile_box_mainsec {
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-align: center;
  width: 50px;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-toggle .profile_imgbox {
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-toggle .profile_imgbox img {
  width: 100%;
  height: auto;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-menu {
  transform: translate(75px, 10px) !important;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-menu::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-right: 20px solid #fff;
  border-bottom: 12px solid transparent;
  position: absolute;
  left: -20px;
  bottom: 22px;
}

.profile_box_mainsec .dropdown-menu {
  margin-top: 0px;
  padding: 0px;
  border-radius: 6px;
  min-width: 220px;
}

.profile_box_mainsec .dropdown_toggle {
  display: block;
  padding-bottom: 5px;
}

.profile_box_mainsec:hover .dropdown-menu.profile-dropdown {
  display: block;
}

.profile_box_mainsec .dropdown-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  padding-top: 15px;
}

.profile_box_mainsec .dropdown-item {
  color: #727b83;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  padding: 12px 20px;
  background-color: #fff;
  border-bottom: 1px solid #e9e9e9;
}

.profile_box_mainsec li:last-child .dropdown-item {
  border-bottom: none;
}

.profile_box_mainsec .dropdown-item:hover {
  color: #fff;
  background-color: #5e38ea;
}

.profile_box_mainsec .dropdown-item svg {
  font-size: 18px;
  vertical-align: middle;
  margin-right: 5px;
  color: #5e38ea;
}

.profile_box_mainsec .dropdown-item:hover svg {
  color: #fff;
}

.profile_box_mainsec .dropdown-item span {
  color: #0b0b13;
}

.profile_box_mainsec .dropdown-item:hover span {
  color: #fff;
}

.addnew_emails_mainsec {
  padding: 25px;
}

.addnew_emails_mainsec .form_maisnec {
  padding-top: 20px;
  border-right: 1px solid #e1d9ff;
  padding-right: 70px;
  padding-bottom: 20px;
  position: relative;
}

.addnew_emails_mainsec .form_maisnec::after {
  content: "Or";
  width: 55px;
  height: 55px;
  background-color: #5e38ea;
  border-radius: 50px;
  padding: 7px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  position: absolute;
  top: 50%;
  right: -56px;
  transform: translate(-50%,-50%);
  text-align: center;
  border: 5px solid #fff;
}

.form_group {
  margin-bottom: 20px;
}

.form_group .form-label {
  color: #5e38ea;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 5px;
}

.form-control {
  background-color: #fff;
  border: 1px solid #dedede !important;
  border-radius: 4px;
  color: #5e38ea;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  padding: 8px 20px;
  outline: none !important;
  box-shadow: none !important;
}

.addnew_emails_mainsec .csv_upload_boxsec {
  padding-top: 20px;
  padding-left: 0px;
  padding-bottom: 10px;
}

.upload_box {
  margin-bottom: 20px;
}

.upload_box .upload_txt {
  border: 2px dashed #bcbcbc;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
}

.upload_box {
  margin-bottom: 20px;
  position: relative;
}

.upload_box .form-control {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.upload_box .upload_txt {
  border: 2px dashed #bcbcbc;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.upload_box .upload_txt svg {
  color: #5e38ea;
  font-size: 30px;
  margin-bottom: 10px;
}

.upload_box .upload_txt .contnt_txt {
  width: 100%;
}

.upload_box .upload_txt .contnt_txt p {
  margin-bottom: 0px;
}

.dashboard_body .tophead_mainsec .right_options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
  gap: 20px;
}

.dashboard_body .tophead_mainsec .right_options .filter_mainbox .form_group .form-control {
  padding: 8px 50px 8px 20px;
}

.dashboard_body .tophead_mainsec .right_options .form_group {
  margin-bottom: 0px;
}

.dashboard_body .tophead_mainsec .right_options .filter_mainbox .form_group .form-control {
  padding: 8px 40px 8px 20px;
  background-image: url("../img/down-icon.png");
  background-repeat: no-repeat;
  background-position: 96%;
  background-size: 24px;
  cursor: pointer;
}

.table_mainsec .more_options .dropdown .dropdown-toggle::after {
  content: "";
  border: none;
}

.table_mainsec .table tbody tr td a svg {
  font-size: 17px;
}

.more_options .dropdown .dropdown-menu {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 0px;
  min-width: 250px;
  overflow: hidden;
}

.more_options .dropdown .dropdown-menu li .dropdown-item {
  color: #323232;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  padding: 13px 25px;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 13px;
}

.more_options .dropdown .dropdown-menu li .dropdown-item:hover {
  background-color: #5e38ea;
  color: #fff;
}

.table_mainsec .table-responsive {
  min-height: 600px;
}

.table_mainsec .more_options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.table_mainsec .more_options .compaign_starstop svg {
  color: #5e38ea;
  font-size: 22px;
  cursor: pointer;
}

.search_mainbox .form_group {
  position: relative;
}

.search_mainbox .form_group .search_icon {
  position: absolute;
  top: 10px;
  right: 14px;
}

.search_mainbox .form_group .form-control {
  border-radius: 50px;
  padding: 8px 40px 8px 25px;
}

.resume_paused_btn.btn_mainsec .btn_style svg {
  margin-right: 5px;
  font-size: 20px;
  vertical-align: middle;
}

.dashboard_body .analytics_mainsec {
  padding: 25px;
}

.compaign_status {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.compaign_status .status_title {
  color: #181230;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.compaign_status .status_result {
  background-color: #5e38ea;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  padding: 5px 15px;
}

.dashboard_body .analytics_mainsec .schedule_btn.btn_mainsec {
  text-align: right;
}

.analytics_mainsec .compaign_infosec {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  margin: 0px -10px;
  margin-top: 50px;
}

.analytics_mainsec .compaign_infosec .compaign_info_box {
  width: 20%;
  padding: 0px 10px;
}

.analytics_mainsec .compaign_infosec .compaign_innerbox {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  padding: 15px;
  text-align: center;
  min-height: 110px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.analytics_mainsec .compaign_infosec .compaign_info_box .title_sec {
  color: #5e38ea;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  width: 100%;
}

.analytics_mainsec .compaign_infosec .compaign_info_box .result_sec {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  line-height: 37px;
}

.analytics_mainsec .step_analytics_table {
  margin-top: 50px;
}

.analytics_mainsec .step_analytics_table .title_sec h3 {
  color: #000;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

.analytics_mainsec .step_analytics_table .table_mainsec .table-responsive {
  min-height: 200px;
}

.schedule_modal .modal-dialog {
  max-width: 900px;
}

.schedule_modal .modal-header .modal-title {
  color: #5e38ea;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
}

.form-group {
  margin-bottom: 25px;
}

.schedule_form .days_mainsec .form_group {
  margin-bottom: 5px;
}

.checkbox_mainsec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}

.custom_checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #e9e4ff;
  border: 1px solid #5e38ea;
  border-radius: 3px;
}

.custom_checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom_checkbox input:checked ~ .checkmark {
  background-color: #5e38ea;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom_checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom_checkbox .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}



/*.dashboard_mainbox {
    background: #fff;
}*/

.dashboard_body .services_mainbox {
  padding: 25px;
  position: relative;
}

.services_mainbox .services_box a {
  background-color: #ddd4ff;
  border: 1px solid #b7a3ff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.services_mainbox .services_box a:hover {
  background-color: #181230;
}

.services_mainbox .services_box .icon_box {
  width: 100%;
  text-align: center;
}

.services_mainbox .services_box .icon_box img {
  width: 80px;
  height: auto;
}

.services_mainbox .services_box .title_box h3 {
  color: #000;
  font-size: 26px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 0px;
}

.services_mainbox .services_box a:hover .title_box h3 {
  color: #fff;
}
.t-c-pages{
  padding-top: 50px;
  padding-bottom: 50px;
}
.t-c-pages h1,.t-c-pages h2, .t-c-pages h3, .t-c-pages h4,.t-c-pages p{
    color: #fff;
}

.t-c-pages h1 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  margin-bottom: 35px;
}

.t-c-pages h2 {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 37px;
  margin-bottom: 10px;
}

.t-c-pages p {
  margin-bottom: 40px;
}

/* ============= Responsive Css Start ============= */

@media only screen and (max-width: 767px) {
.menu_sidebar {
  padding: 10px;
  width: 60px;
  min-width: 60px;
}

.menu_sidebar .logo_box img {
  width: 40px;
}

.profile_box_mainsec {
  left: 12px;
  width: 40px;
}

.profile_box_mainsec .nav-item.dropdown .dropdown-toggle .profile_imgbox {
  width: 40px;
  height: 40px;
}

.dashboard_body {
  padding-left: 60px;
  min-height: 400px;
}

.dashboard_body .tophead_mainsec {
  padding: 10px 20px 10px;
}
}

/* ============= Responsive Css End ============= */

































